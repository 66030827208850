<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0" style="margin: 0 auto !important;">
      <div class="main_block">
        <div class="header_block_div">
          <div class="reg_logo_section">
            <a class="brand-logo" @click="toAequitex()">
              <img src="../../assets/images/logo/logo-green.png"/>
            </a>
          </div>
          <div class="reg-lang-section">
            <div class="register-languages-mobile small_screen_lang" align="end">
              <b-dropdown
                  text="Dark"
                  variant="outline-dark"
                  id="dropdown-grouped"
                  class="dropdown-language no-border-button"
                  right
              >
                <template #button-content>
                  <b-img
                      :src="currentLocale.img"
                      height="14px"
                      width="22px"
                      :alt="currentLocale.locale"
                  />
                  <span class="ml-50 text-body">{{ $t(currentLocale.name) }}</span>
                </template>
                <b-dropdown-item
                    v-for="localeObj in locales"
                    :key="localeObj.locale"
                    @click="$i18n.locale = localeObj.locale"
                >
                  <b-img
                      :src="localeObj.img"
                      height="14px"
                      width="22px"
                      :alt="localeObj.locale"
                  />
                  <span class="ml-50">{{ $t(localeObj.name) }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>

        <div class="reg-content-section">

          <b-col  class="d-lg-flex align-items-center p-5 register_section_buttons" style="background-color:#F7F6F4; padding: 0 !important;">
            <b-row class="col-md-12 flex-column align-items-start">
              <!--<b-col lg="4" class="d-none d-lg-flex align-items-center p-5" style="background-color:#fff ;">-->
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5"
                >
                  <b-img fluid style="width: 100%;" :src="imgUrl" alt="Login V2" />
                </div>
              <!--</b-col>-->
            </b-row>
          </b-col>

          <b-col class="row auth-bg px-1 p-2 pt-3" style="background-color:#F7F6F4; margin: 0 auto">
            <div class="form_block">
              <div class="form_block_header ">
                <div class="col-md-12">
                  <h4 class="row justify-content-between">
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold col-md-12 pl-0  pb-0 mb-0"
                    >
                      {{ $t("login_text") }}
                    </b-card-title>

                  </h4>
                </div>
              </div>
              <b-col v-if="!hasHash" sm="12" md="12" lg="12" class="px-xl-2 mx-auto mt-2" style="height: 523px;">
                <div class="col-md-12">
                  <h4 class="row justify-content-between">
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-2 col-md-12 pl-0"
                    >
                      {{ $t("welcome_to_hive") }}
                    </b-card-title>
                  </h4>
                </div>

                <b-card-text class="mb-2">
                  {{ $t("login_subtitle") }}
                </b-card-text>

                <!-- form -->
                <validation-observer ref="loginValidation">
                  <b-form class="auth-login-form mt-2" @submit.prevent>
                    <!-- email -->
                    <b-form-group :label="$t('email')" label-for="login-email">
                      <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="required|email"
                      >
                        <b-form-input
                            id="login-email"
                            v-model.trim="userEmail"
                            :state="errors.length > 0 ? false : null"
                            name="login-email"
                            tabindex="1"
                            placeholder="john@example.com"
                            :disabled="disableInputs"
                        />
                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- forgot password -->
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="login-password">{{ $t("password") }}</label>
                      </div>
                      <validation-provider
                          #default="{ errors }"
                          name="Password"
                          rules="required"
                      >
                        <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                              id="login-password"
                              v-model.trim="password"
                              tabindex="2"
                              :state="errors.length > 0 ? false : null"
                              class="form-control-merge"
                              :type="passwordFieldType"
                              name="login-password"
                              placeholder="············"
                              :disabled="disableInputs"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                                class="cursor-pointer"
                                :icon="passwordToggleIcon"
                                @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- checkbox -->
                    <div class="d-flex justify-content-between">
                      <b-form-group>
                        <b-form-checkbox
                            id="remember-me"
                            v-model.trim="status"
                            tabindex="3"
                            name="checkbox-1"
                        >
                          {{ $t("remember_me") }}
                        </b-form-checkbox>
                      </b-form-group>
                      <b-link  :to="{ name: 'forgot-password' }">
                        <small> {{ $t("forgot_password") }} </small>
                      </b-link>
                    </div>

                    <!-- submit buttons -->
                    <b-button
                        type="submit"
                        variant="primary"
                        block
                        tabindex="4"
                        @click="validationForm()"
                    >
                      {{ $t("sign_in") }}
                    </b-button>

                  </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                  <span> {{ $t("new_on_our_platform") }} </span>
                  <b-link tabindex="5"  :to="{ name: 'register' }">
                    <span>&nbsp;{{ $t("create_an_account") }}</span>
                  </b-link>
                </b-card-text>
              </b-col>
              <b-col v-else sm="12" md="12" lg="12" class="px-xl-2 mx-auto mt-2 justify-center text-center"></b-col>
            </div>
          </b-col>

          <b-col class="pb-5" style="background: rgb(247, 246, 244);">
            <iframe width='100%' height='730px' src='https://support-aequitex.zohobookings.eu/portal-embed#/customer/167264000000318034' frameborder='0' allowfullscreen=''></iframe>
          </b-col>

        </div>

      </div>



      <!-- /Login-->
    </b-row>


    <!-- Buyer modal -->
    <b-modal v-model="showModal" :title="$t('register_seller_button')" hide-footer size="lg" class="custom_modal">
      <div class="modal_section">
        <ul type="circle">
          <li>
            {{ $t('seller_section1_paragraph') }}
          </li>
          <ul type="square">
            <li>{{ $t('seller_section1_answer1') }}</li>
            <li>{{ $t('seller_section1_answer2') }}</li>
            <li>{{ $t('seller_section1_answer3') }}</li>
          </ul>
        </ul>

      </div>

      <div class="modal_section">
        <ul type="circle">
          <li>
            {{ $t('seller_section2_paragraph') }}
          </li>
          <ul type="square">
            <li>{{ $t('seller_section2_answer1') }}</li>
            <li>{{ $t('seller_section2_answer2') }}</li>
            <li>{{ $t('seller_section2_answer3') }}</li>

            <ul type="square">
              <li>{{ $t('seller_section2_answer4') }}</li>
              <li>{{ $t('seller_section2_answer5') }}</li>
            </ul>
          </ul>
        </ul>
      </div>
    </b-modal>

    <!-- Seller modal -->
    <b-modal v-model="showModal2" :title="$t('register_buyer_button')" hide-footer size="lg" class="custom_modal">
      <div class="modal_section">
        <ul type="circle">
          <li>
            {{ $t('buyer_section1_paragraph') }}
          </li>
          <ul type="square">
            <li>{{ $t('buyer_section1_answer1') }}</li>
            <li>{{ $t('buyer_section1_answer2') }}</li>
          </ul>
        </ul>

      </div>

      <div class="modal_section">
        <ul type="circle">
          <li>
            {{ $t('seller_section2_paragraph') }}
          </li>
          <ul type="square">
            <li>{{ $t('buyer_section2_answer1') }}</li>
            <li>{{ $t('buyer_section2_answer2') }}</li>
            <li>{{ $t('buyer_section2_answer3') }}</li>
            <li>{{ $t('buyer_section2_answer4') }}</li>
            <li>{{ $t('buyer_section2_answer5') }}</li>
          </ul>
        </ul>
      </div>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import axios from "axios";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      amount: "",
      sideImg: require("@/assets/images/pages/login-v5.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      disableInputs: false,
      language: "en",
      locales: [
        {
          locale: "en",
          img: "/images/flags/en.png",
          name: "english",
        },

        {
          locale: "de",
          img: "/images/flags/german.png",
          name: "german",
        },
      ],
      hasHash: false,
      resMessage: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v5.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
    currentLocale() {
      let obj = null;
      if (this.$i18n.locale) {
        if (this.$i18n.locale == "en") {
          obj = {
            locale: "en",
            img: "/images/flags/en.png",
            name: "english",
          };
        } else {
          obj = {
            locale: "de",
            img: "/images/flags/german.png",
            name: "german",
          };
        }
      } else {
        obj = {
          locale: "en",
          img: "/images/flags/en.png",
          name: "english",
        };
      }
      this.$store.commit("app/UPDATE_LANGUAGE", obj.locale);
      return obj;
    },
  },
  created() {
    if(this.$route.params.hash){
      this.hasHash = true;
      this.$http.post(`/confirm/invoice/${this.$route.params.hash}`).then((res) => {
        if(res){
          this.resMessage = res.data.message;
        }
      });
    }
  },

  methods: {
    toAequitex(){
      window.location = 'https://www.aequitex.com/'
    },
    toggleLanguage(language) {
      this.$i18n.locale = language;
      this.language = language;
      this.$store.commit("app/UPDATE_LANGUAGE", language);
    },
    goToLink(){
      window.location = 'http://marketing.aequitex.com/CH-EN/aequitex-pre-register-campaign-website';
    },
    async validationForm() {
  try {
    const success = await this.$refs.loginValidation.validate();
    if (success) {
      this.disableInputs = true;

      if (typeof this.$http === "undefined") {
        window.location.reload();
      }

      const res = await this.$http.post("/login", {
        email: this.userEmail,
        password: this.password,
        remember: this.status,
        language: this.currentLocale.locale,
      });

      if (res.data.status === 200) {
        const user = res.data.value;
        const params = new URLSearchParams(window.location.search);
        this.$http
            .post(`check-user-two-factor/${user.id}`, { 'check_code': true })
            .then((res) => {
              if (res.data.value.bool) {
                  this.$store.commit("auth/SET_AUTHENTICATED", true);
                  this.$store.commit("auth/SET_USER", user);
                  if(params.has('form')) {
                    window.location.href = "/two-factor-verify?form";
                  }else {
                    this.$router.push("/two-factor-verify");
                  }
              } else {
                this.$store.commit("auth/SET_AUTHENTICATED", true);
                this.$store.commit("auth/SET_USER", user);
                this.$store.commit("auth/UPDATE_TOKEN", user.token);
                this.$store.commit("auth/SHOW_ALERT", true);

                if (user.status === 1) {

                  if (params.has('source')) {
                    if(params.has('id')) {
                      window.location.href = "/" +  params.get('source') + '/' + params.get('id') ;
                    }else {
                      window.location.href = "/" +  params.get('source');
                    }
                  } else if(params.has('form')) {
                    window.location.href = "/cookpit/task/fill-online-form";
                  } else {
                    window.location.href = "/";
                  }

                } else {
                  this.$router.push("/verify");
                }
              }
            });


        // this.$store.commit("auth/SET_AUTHENTICATED", true);
        // this.$store.commit("auth/SET_USER", user);
        // this.$store.commit("auth/UPDATE_TOKEN", user.token);
        // this.$store.commit("auth/SHOW_ALERT", true);
        //
        // if (user.status === 1) {
        //
        //   const params = new URLSearchParams(window.location.search);
        //   if (params.has('source')) {
        //     if(params.has('id')) {
        //       window.location.href = "/" +  params.get('source') + '/' + params.get('id') ;
        //     }else {
        //       window.location.href = "/" +  params.get('source');
        //     }
        //   } else {
        //     window.location.href = "/";
        //   }
        //
        // } else {
        //   this.$router.push("/verify");
        // }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("successfully_logged_in"),
            icon: "SuccessIcon",
            variant: "success",
          },
        });
      } else {
        this.$store.commit("auth/UPDATE_TOKEN", null);
        this.disableInputs = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("failed_to_authenticate_with_provided_credentials."),
            icon: "ErrorIcon",
            variant: "error",
          },
        });
      }
    }
  } catch (error) {
    this.$store.commit("auth/SET_USER", []);
    this.$store.commit("auth/SET_AUTHENTICATED", false);
    this.$store.commit("auth/UPDATE_TOKEN", null);
    this.disableInputs = false;
    this.$toast({
      component: ToastificationContent,
      props: {
        title: this.$t("failed_to_authenticate_with_provided_credentials."),
        icon: "ErrorIcon",
        variant: "error",
      },
    });
  }
},
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.header_block_div > a, .header_block_div > div {
  width: 10%;
}

.form_block {
  background-color: white;
  width: 100%;
  border-radius: 25px;
  overflow: hidden;
  height: fit-content;
  /*margin-top: 160px*/
}

.form_block .form_block_header {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #555;
  padding: 20px;
}

.form_block .form_block_header h2, .form_block .form_block_header span {
  color: #222f28;
}

.form_block input, .form_block select {
  background-color: #F7F6F4;
}

.form_block button {
  background-color: #577264 !important;
}

.modal_section {
  width: 98%;
  min-height: 150px;
  height: auto;
  padding: 20px 10px 20px 20px;
  font-weight: 500;
}

.modal_section:first-of-type {
  border-bottom: 1px solid #ccc;
}

.custom_modal .modal-dialog {
  max-width: 1200px !important;
}

.modal_section li {
  margin: 15px 0;
}

.register_section_buttons p {
  margin: 15px 0;
  font-size: 16px;
}

/*.small_screen_lang {*/
/*display: none;*/
/*}*/

.large_screen_lang {
  display: block;
}

/*@media screen and (max-width: 700px) {*/
/*.form_block {*/
/*margin-bottom: 70px !important;*/
/*}*/
/*}*/

@media screen and (max-width: 992px) {
  /*.form_block {*/
  /*margin: 0 20px;*/
  /*}*/
  .register_section_buttons {
    margin-top: 25px;
    padding: 0 40px !important;
  }

  .small_screen_lang {
    display: block;
    margin: 25px 0;
    text-align: right !important;
  }

  .large_screen_lang {
    display: none;
  }
}

</style>
<style scoped>

  .main_block {
    width: 2100px;
    max-width: 2100px;
    margin: 0 auto;
  }

  .reg-content-section {
    width: 100%;
    height: 100%;
    display: flex;
    /*flex-direction: column;*/
  }

  .reg-content-section > div {
    width: 33%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .header_block_div {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #F7F6F4
  }

  .brand-logo {
    position: unset !important;
  }

  .reg_logo_section {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reg-lang-section {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  iframe {
    margin-top: 150px;
  }

  @media screen and (max-width: 1250px) {

    .header_block_div > div {
      width: 50%;
    }

    .reg-content-section {
      flex-direction: column;
    }

    .reg-content-section > div {
      width: 100%;
      justify-content: center;
    }

    .register_section_buttons {
      margin: 0 !important;
    }

    .register_section_buttons ul{
      margin: 0 auto;
    }

    .form_block {
      width: 50%;
    }

    iframe {
      width: 50%;
    }
  }

  @media screen and (max-width: 800px) {
    .form_block {
      width: 100%;
      margin: 0 !important;
      padding: 0 !important;
    }

    iframe {
      margin-top: 40px    ;
      width: 100%;
    }

    .register_section_buttons h1{
      font-size: 22px;
    }

    .register_section_buttons p{
      font-size: 14px;
    }
  }

</style>

